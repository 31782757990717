import { FC } from 'react';
import { Box } from '@stashinvest/ui';
import { TrackedLinkColors, FormattedDate, TooltipWithTag } from '@stashinvest/shared-ui';
import { BankAccountsWithBalance } from '@stashinvest/shared-types/schemas/user';

import { bankAccountStates, unknownStateTag } from 'src/constants/bankAccountStates';
import { AccountCard } from 'src/components/AccountCard';
import { FalseyProp } from 'src/components/FalseyProp';
import { BankPortalLink } from 'src/components/BankPortalLink';
import { DomainLabel, Domains } from 'src/components/DomainLabel';
import { BankAccountStatus, BankingPartner } from 'src/types/global';

export type BankAccountsListPropsV2 = {
	bankAccounts?: BankAccountsWithBalance[];
	userId: string;
	trackingPage: string;
};

export const BankAccountsV2: FC<BankAccountsListPropsV2> = ({
	bankAccounts,
	userId,
	trackingPage,
}: BankAccountsListPropsV2) => {
	if (!bankAccounts) {
		return null;
	}

	// To keep the legacy accounts at the bottom we actually filter the render in UserPage
	// So there is no point of sorting them here, unless we want to render Legacy Bank Accounts
	// before Invest Accounts

	return bankAccounts.length ? (
		<>
			{bankAccounts.map((bankAccount) => {
				const tag =
					bankAccountStates.get(
						bankAccount.accountState.status.toUpperCase() as BankAccountStatus
					) || unknownStateTag;
				const statusChange = bankAccount.accountStatusChangedDateTime;
				const path = `users/${userId}/accounts/${bankAccount.id}`;
				const legacy = bankAccount.partner === BankingPartner.GREENDOT.toLowerCase();

				return (
					<AccountCard
						key={bankAccount.id}
						title={
							<BankPortalLink
								color={TrackedLinkColors.textPrimary}
								path={path}
								tracking={{
									page: trackingPage,
									component: 'debit-account-card-title',
								}}
							>
								Debit Account
							</BankPortalLink>
						}
						tag={tag}
					>
						<DomainLabel
							domain={Domains.Bank}
							style={{
								position: 'absolute',
								top: '-35px',
								right: 0,
							}}
						/>
						<FalseyProp
							property="Available balance"
							value={
								<Box pl="10px">
									{bankAccount._errors?.availableBalance ? (
										<TooltipWithTag
											description={bankAccount._errors.availableBalance.description}
											variant="danger"
											data-testid="error-text"
										>
											{bankAccount._errors.availableBalance.message}
										</TooltipWithTag>
									) : (
										bankAccount?.availableBalance
									)}
								</Box>
							}
						/>
						<FalseyProp
							property="Account status updated"
							value={
								<Box pl="10px" data-testid="status-updated-bank-card">
									<FormattedDate timestamp={statusChange}></FormattedDate>
								</Box>
							}
						/>

						{legacy ? (
							<Box pt="10px" pb="30px">
								Legacy Bank Account
							</Box>
						) : (
							<BankPortalLink
								path={path}
								tracking={{
									page: trackingPage,
									component: 'debit-account-card',
								}}
								py="10px"
							>
								View in Bank Portal
							</BankPortalLink>
						)}
					</AccountCard>
				);
			})}
		</>
	) : null;
};
