import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Text } from '@stashinvest/ui/dist/es/2';

export enum Domains {
	Invest = 'Invest',
	Bank = 'Bank',
}

enum DomainText {
	Invest = 'Invest',
	Bank = 'Bank',
}

enum DomainColors {
	Invest = '#DAC3FF',
	Bank = '#A5E1FF',
}

export interface DomainLabelProps {
	domain: Domains;
	style?: CSSProperties;
}

export const DomainLabel = ({ domain = Domains.Invest, style }: DomainLabelProps) => (
	<Text.Label16 fontWeight="bold" style={style}>
		<Label color={DomainColors[domain]}>{DomainText[domain]}</Label>
	</Text.Label16>
);

interface LabelProps {
	color: DomainColors;
}

const Label = styled.span<LabelProps>`
	display: block;
	text-transform: uppercase;
	font-style: italic;
	color: ${({ color }) => color || 'inherit'};
`;
