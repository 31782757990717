import styled from 'styled-components';
import { Flex, Box } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';
import { TrackedLink, TrackedLinkVariants, FormattedDate } from '@stashinvest/shared-ui';
import { UserLockReasonMap } from '@stashinvest/shared-types/schemas/userLocks';
import { FraudReasonMap, FraudReasonKey } from '@stashinvest/shared-types/fraud';
import { lockReasons, LockReasonProps, LockReasonKey } from 'src/constants/lockReasons';
import { fraudReasons, FraudReasonProps } from 'src/constants/fraudReasons';

const verifyLockFraudReasons = ({ reason, fraudTag }: LockItemsProps) => {
	let potentialReason: LockReasonProps | FraudReasonProps | undefined;
	let potentialTitle: string;
	let invalidReason: number | string = '';
	if (fraudTag !== undefined) {
		potentialReason = fraudReasons[FraudReasonMap[fraudTag as FraudReasonKey]];
		potentialTitle = potentialReason?.title;
		invalidReason = fraudTag;
	} else if (reason !== undefined) {
		potentialReason = lockReasons[reason as LockReasonKey];
		potentialTitle = potentialReason?.title;
		invalidReason = reason;
	}

	const invalidTitles = [
		lockReasons[UserLockReasonMap.noLockReason]?.title,
		lockReasons[UserLockReasonMap.unknown]?.title,
		fraudReasons[FraudReasonMap.unknown_reason]?.title,
	];
};

const LockRow = styled(Flex).attrs(() => ({
	justifyContent: 'space-between',
	flexDirection: { mobile: 'column', tablet: 'row' },
}))`
	border-bottom: 1px solid ${({ theme }) => theme.colors.softBlack};

	&:last-of-type {
		padding-bottom: 0;
		border-bottom: 0;
	}
`;

interface LockItemsProps {
	reason?: number;
	fraudTag?: string;
	fraudTagAppliedAt?: string;
}

export const LockItems = ({ reason, fraudTag, fraudTagAppliedAt }: LockItemsProps) => {
	// Title is always available in both maps, so assigning an empty string won't break the FE
	let reasonFromDict: LockReasonProps | FraudReasonProps = { title: '' };

	if (fraudTag !== undefined) {
		reasonFromDict =
			fraudReasons[FraudReasonMap[fraudTag as FraudReasonKey]] ||
			fraudReasons[FraudReasonMap.unknown_reason];
	} else if (reason !== undefined) {
		reasonFromDict =
			lockReasons[reason as LockReasonKey] || lockReasons[UserLockReasonMap.unknown];
	}

	const title = reasonFromDict.title;
	const description = reasonFromDict.description;
	const knowledgeBaseLink = reasonFromDict.knowledgeBaseLink;
	const nextStepLink =
		'nextStepLink' in reasonFromDict ? reasonFromDict.nextStepLink : null;
	const optionalTitle =
		'optionalTitle' in reasonFromDict ? reasonFromDict.optionalTitle : null;
	const optionalDescription =
		'optionalDescription' in reasonFromDict ? reasonFromDict.optionalDescription : null;

	// raise errors for any missing or invalid lock reasons
	verifyLockFraudReasons({ reason: reason, fraudTag: fraudTag });

	return (
		<LockRow py="10px">
			<Flex flexDirection="column" pr="20px">
				<Text.Label16 color="textNegativeSecondary">
					<Text.Label16
						as="span"
						pr="5px"
						fontWeight="bold"
						color="textNegativeSecondary"
					>
						{title}
						{description ? ': ' : null}
					</Text.Label16>
					{description ? description : null}
				</Text.Label16>
				{Boolean(optionalTitle && optionalDescription) ? (
					<Text.Label16 pt="15px" color="textNegativeSecondary">
						<Text.Label16
							as="span"
							pr="5px"
							fontWeight="bold"
							color="textNegativeSecondary"
						>
							{optionalTitle}
							{': '}
						</Text.Label16>
						{optionalDescription}
					</Text.Label16>
				) : null}
				<Text.Label16 pt="15px" color="textNegativeSecondary" pb="10px">
					{fraudTagAppliedAt ? 'Locked on' : null}
					{fraudTagAppliedAt ? (
						<FormattedDate
							timestamp={`${new Date(fraudTagAppliedAt).toISOString()}`}
							showTimezone
						/>
					) : null}
				</Text.Label16>
			</Flex>
			<Flex
				width={{ mobile: '100%', tablet: '216px' }}
				flexShrink="0"
				my={{ mobile: '10px', tablet: '0' }}
				flexDirection="column"
				alignItems="center"
			>
				{knowledgeBaseLink ? (
					<Box mb={nextStepLink ? '10px' : '0'} display="grid">
						<TrackedLink
							href={knowledgeBaseLink}
							target="_blank"
							type={TrackedLinkVariants.SECONDARY_BUTTON}
							tracking={{
								page: 'user',
								component: 'lock-items',
								label: 'knowledge-base-link',
							}}
						>
							View Knowledge Base
						</TrackedLink>
					</Box>
				) : null}
				{nextStepLink ? (
					<Box display="grid" pb="5px">
						{nextStepLink}
					</Box>
				) : null}
			</Flex>
		</LockRow>
	);
};
