import { FC, useCallback, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
	CardWithPropTable,
	FormattedDate,
	TooltipEmoji,
	SearchIconButton,
	EditButtonModalOpener,
	EditButton,
	CardTitle,
	ErrorContainer,
	VisibilityProvider,
} from '@stashinvest/shared-ui';
import {
	countryCodeToName,
	isContentOnlyUserProfile,
	stateCodeToName,
	UserProfile,
} from '@stashinvest/shared-types';

import { ActionModal } from 'src/components/ActionModal/ActionModal';
import { ErrorMessage, LoadingErrorWrapper } from 'src/components/LoadingErrorWrapper';
import { PageContainer } from 'src/components/PageContainer';
import { PageWrapper } from 'src/components/PageWrapper';
import { Grid } from 'src/components/Grid';
import { useUserProfileV2 } from 'src/hooks/useUserProfileV2';
import { formatPhoneNumber } from 'src/utils/displayFormatter';
import { config } from 'src/config/config';
import { EditCitizenshipDetailsForm } from './components/EditCitizenshipDetailsForm';
import { EditPermanentAddressForm } from './components/EditPermanentAddressForm/EditPermanentAddressForm';
import { EditMailingAddressForm } from './components/EditMailingAddressForm/EditMailingAddressForm';

type UserPageParams = { userId: string };

const permAddressLabels = {
	streetAddress: 'Street Address',
	streetAddress2: 'Apartment, Suite etc.',
	city: 'City',
	state: 'State',
	postalCode: 'Zip',
	country: 'Country',
	deliveryIndicator: 'Address Type',
};

const mailingAddressLabels = {
	streetAddress: 'Street Address',
	streetAddress2: 'Apartment, Suite etc.',
	city: 'City',
	state: 'State',
	postalCode: 'Zip',
	country: 'Country',
};

const generateAddressRows = (
	labels: typeof mailingAddressLabels | typeof permAddressLabels,
	address:
		| UserProfile['addresses']['mailingAddress']
		| UserProfile['addresses']['primaryAddress']
) => {
	const addressKeys = Object.keys(labels) as (keyof typeof labels)[];

	return addressKeys.map((key) => {
		let value = address?.[key] && address[key];

		if (key === 'country' && value) {
			const countryDisplayName = countryCodeToName[value];
			value = countryDisplayName ? countryDisplayName : value;
		} else if (key === 'state' && value) {
			const stateDisplayName = stateCodeToName[value];
			value = stateDisplayName ? stateDisplayName : value;
		}

		return {
			title: labels[key],
			value: value || '--',
		};
	});
};

export enum AccountDetailsModals {
	ADDRESS_UPDATE_OVERWRITTEN,
}

export const AccountDetails: FC = () => {
	const { userId } = useParams<UserPageParams>() as UserPageParams;
	const { isLoading: loading, error, data: response } = useUserProfileV2(userId);
	const [modal, setModal] = useState<AccountDetailsModals>();
	const navigate = useNavigate();

	const closeModal = useCallback(() => setModal(undefined), []);

	const emailVerified = Boolean(response?.info.emailVerified);
	const phoneVerified = Boolean(response?.info.phoneVerified);
	let phoneType = '--';
	let permResident = '--';

	const fullName =
		!response?.profile.firstName || !response?.profile.lastName
			? undefined
			: `${response?.profile.firstName} ${response?.profile.lastName}`;

	if (response && 'phoneNumberType' in response.info && response.info.phoneNumberType) {
		switch (response.info.phoneNumberType) {
			case 'HOME':
				phoneType = 'Home';
				break;
			case 'MOBILE':
				phoneType = 'Mobile';
		}
	}

	if (
		response &&
		'nationality' in response &&
		response.nationality.permanentResident != null
	) {
		switch (response.nationality.permanentResident) {
			case true:
				permResident = 'Yes';
				break;
			case false:
				permResident = 'No';
		}
	}

	const fullResponse = response as UserProfile;

	return (
		<PageContainer name={fullName} showSearchBox>
			<PageWrapper title="Account Details">
				<LoadingErrorWrapper loading={loading} error={error}>
					{response ? (
						isContentOnlyUserProfile(response) && !response.profile.ssn ? (
							<ErrorContainer>
								<ErrorMessage
									error={'This page is not accessible for content only users'}
								/>
							</ErrorContainer>
						) : (
							<Grid>
								<CardWithPropTable
									card={{
										title: (
											<CardTitle title="Personal Details">
												<EditButton
													onClick={() =>
														(window.location.href = `${
															config.retoolCxPortalUrl
														}/app/personal-details#userid=${userId}&firstname=${fullResponse
															?.profile.firstName}&lastname=${fullResponse?.profile
															.lastName}&dob=${fullResponse?.profile
															.dateOfBirth}&email=${encodeURIComponent(
															fullResponse?.info.email
														)}&phone=${fullResponse?.info
															.phoneNumber}&phonetype=${fullResponse?.info
															.phoneNumberType}&addressline1=${fullResponse?.addresses
															.primaryAddress?.streetAddress}&addressline2=${fullResponse
															?.addresses.primaryAddress
															?.streetAddress2}&city=${fullResponse?.addresses
															.primaryAddress?.city}&state=${fullResponse?.addresses
															.primaryAddress?.state}&zip=${fullResponse?.addresses
															.primaryAddress?.postalCode}&country=${fullResponse
															?.addresses.primaryAddress?.country}`)
													}
												/>
											</CardTitle>
										),
									}}
									tracking={{
										page: 'Account Details',
										component: 'personal-details-tile',
									}}
									table={{
										rows: [
											{
												title: 'Name',
												value: (
													<>
														{fullName ?? '--'}
														{fullName ? (
															<SearchIconButton
																to={`/search?firstName=${fullResponse.profile.firstName}&lastName=${fullResponse.profile.lastName}`}
																tracking={{
																	page: 'Account Details',
																	component: 'personal-details-tile',
																	label: 'name-search',
																}}
																top="10px"
															>
																Search for duplicate accounts by first &amp; last name
															</SearchIconButton>
														) : null}
													</>
												),
											},
											{
												title: 'DOB',
												value: (
													<FormattedDate
														timestamp={fullResponse.profile.dateOfBirth?.toString()}
														timezone="Etc/UTC"
													/>
												),
											},
											{
												title: 'SSN (last 4 digits)',
												value: fullResponse.profile.ssn ? fullResponse.profile.ssn : '--',
											},
											{
												title: 'Email Address',
												value: (
													<>
														<span
															data-testid="email"
															style={{
																overflowWrap: 'anywhere',
															}}
														>
															{fullResponse?.info.email}
														</span>
														<TooltipEmoji
															emojiLabel={emailVerified ? 'Email verified' : 'Unverified'}
															description={
																emailVerified ? 'Email verified' : 'Unverified'
															}
															style={{
																position: 'absolute',
																right: 0,
																paddingLeft: '5px',
																top: '6px',
															}}
														>
															{emailVerified ? '✅' : '❓'}
														</TooltipEmoji>
													</>
												),
											},
											{
												title: 'Phone Type',
												value: phoneType,
											},
											{
												title: 'Phone Number',
												value: (
													<>
														{formatPhoneNumber(fullResponse.info.phoneNumber) ?? '--'}
														{fullResponse.info.phoneNumber ? (
															<SearchIconButton
																to={`/search?phone=${fullResponse.info.phoneNumber}`}
																tracking={{
																	page: 'Account Details',
																	component: 'personal-details-tile',
																	label: 'phonenumber-search',
																}}
																top="10px"
															>
																Search for duplicate accounts by Phone Number
															</SearchIconButton>
														) : null}
														<TooltipEmoji
															emojiLabel={
																phoneVerified ? 'Phone number verified' : 'Unverified'
															}
															description={
																phoneVerified ? 'Phone number verified' : 'Unverified'
															}
															style={{
																position: 'absolute',
																right: 0,
																paddingLeft: '5px',
																top: '6px',
															}}
														>
															{phoneVerified ? '✅' : '❓'}
														</TooltipEmoji>
													</>
												),
											},
										],
										labelWidth: 170,
									}}
								/>

								<CardWithPropTable
									card={{
										title: (
											<CardTitle title="Citizenship Details">
												<EditButtonModalOpener label="edit citizenship details">
													<EditCitizenshipDetailsForm userProfile={fullResponse} />
												</EditButtonModalOpener>
											</CardTitle>
										),
									}}
									tracking={{
										page: 'Account Details',
										component: 'citizenship-details-tile',
									}}
									table={{
										rows: [
											{
												title: 'Citizenship Country',
												value:
													fullResponse.nationality.citizenshipCountry && // TODO: copy formatText (formatText & lookupOrFormat) utils from bank portal and use here
													countryCodeToName[fullResponse.nationality.citizenshipCountry]
														? countryCodeToName[
																fullResponse.nationality.citizenshipCountry
														  ]
														: '--',
											},
											{
												title: 'Permanent Resident',
												value: permResident,
											},
											{
												title: 'Birth Country',
												value:
													fullResponse.nationality.birthCountry && // TODO: copy formatText (formatText & lookupOrFormat) utils from bank portal and use here
													countryCodeToName[fullResponse.nationality.birthCountry]
														? countryCodeToName[fullResponse.nationality.birthCountry]
														: '--',
											},
											{
												title: 'Visa Type',
												value: fullResponse.nationality.visaType
													? fullResponse.nationality.visaType
													: '--',
											},
											{
												title: 'Visa Expiration',
												value: fullResponse.nationality.visaExpirationDate ? (
													<FormattedDate
														timestamp={fullResponse.nationality.visaExpirationDate.toString()}
														timezone="Etc/UTC"
													/>
												) : (
													'--'
												),
											},
										],
										labelWidth: 170,
									}}
								/>

								<CardWithPropTable
									card={{
										title: (
											<CardTitle title="Permanent Address">
												<EditButtonModalOpener label="edit permanent address">
													<EditPermanentAddressForm
														userProfile={fullResponse}
														setModal={setModal}
													/>
												</EditButtonModalOpener>
											</CardTitle>
										),
									}}
									tracking={{
										page: 'Account Details',
										component: 'perm-address-tile',
									}}
									table={{
										rows: generateAddressRows(
											permAddressLabels,
											fullResponse.addresses.primaryAddress
										),
										labelWidth: 170,
									}}
								/>

								<CardWithPropTable
									card={{
										title: (
											<CardTitle title="Mailing Address">
												<EditButtonModalOpener label="edit mailing address">
													<EditMailingAddressForm userProfile={fullResponse} />
												</EditButtonModalOpener>
											</CardTitle>
										),
									}}
									tracking={{
										page: 'Account Details',
										component: 'mailing-address-tile',
									}}
									table={{
										rows: fullResponse.addresses.mailingAddressIsSame
											? generateAddressRows(
													mailingAddressLabels,
													fullResponse.addresses.primaryAddress
											  )
											: generateAddressRows(
													mailingAddressLabels,
													fullResponse.addresses.mailingAddress
											  ),
										labelWidth: 170,
									}}
								/>
							</Grid>
						)
					) : null}
				</LoadingErrorWrapper>
			</PageWrapper>
			{/** Modals */}
			<VisibilityProvider isOpen={true}>
				{modal === AccountDetailsModals.ADDRESS_UPDATE_OVERWRITTEN ? (
					<ActionModal
						handleConfirm={closeModal}
						onClose={closeModal}
						title="Personal Details Update"
						body="The address was updated with some corrections based on USPS address validation"
						confirmBtnText="Close"
					/>
				) : null}
			</VisibilityProvider>
		</PageContainer>
	);
};
